<template>
  <div class="nav-main">
    <div class="nav-item"
         v-for="(item,index) in navs"
         :key="index"
         :class="{'is-active':index===activeIndex}"
         @click="toggleNav(item,index)">
      <i class="nav-icon"
         :class="item.icon"></i>
      <div class="nav-title">{{ item.name }}</div>
    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      navs: [{
        icon: 'el-icon-menu',
        name: '大屏管理',
        path: '/'
      }, {
        icon: 'el-icon-folder-opened',
        name: '大屏分类',
        path: '/category'
      }, {
        icon: 'el-icon-set-up',
        name: '数据源',
        path: '/db'
      }, {
        icon: 'el-icon-suitcase',
        name: '数据集',
        path: '/record'
      }, {
        icon: 'el-icon-eleme',
        name: '组件库',
        path: '/components'
      }, {
        icon: 'el-icon-s-opportunity',
        name: '全局变量',
        path: '/glob'
      }, {
        icon: 'el-icon-message',
        name: '消息渠道',
        path: '/task'
      }, {
        icon: 'el-icon-files',
        name: '静态资源',
        path: '/file'
      }, {
        icon: 'el-icon-location-information',
        name: '地图资源',
        path: '/map'
      }, {
        icon: 'el-icon-thumb',
        name: '工具箱',
        path: '/document'
      }, {
        icon: 'el-icon-user',
        name: '智能AI-开发中',
        path: '/chatgpt'
      }, {
        icon: 'el-icon-document',
        name: '请求日志',
        path: '/logs'
      }],
      activeIndex: 0,
    }
  },
  mounted () {
    let index = this.navs.findIndex(ele => ele.path === this.$route.path)
    this.activeIndex = index
  },
  methods: {
    toggleNav (nav, index) {
      this.activeIndex = index;
      this.$emit('change', nav, index)
    }
  }
}
</script>

<style lang="scss" scoped>
.nav-main {
  display: flex;
  flex-direction: column;
  flex: 1;
  user-select: none;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.nav-item {
  position: relative;
  height: 40px;
  display: flex;
  flex-shrink: 0;
  margin: 5px 8px;
  line-height: 40px;
  align-items: center;
  cursor: pointer;
  border-radius: 3px;
  color: #dadadb;
  font-size: 14px;
  &:hover {
    color: var(--primary-color);
    background-color: #223442;
  }
  &.is-active {
    color: var(--primary-color);
    background-color: #223442;
    font-size: 15px;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      width: 3px;
      height: 50%;
      border-radius: 6px;
      transform: translateY(-50%);
      background-color: var(--primary-color);
    }
  }
}
.nav-icon {
  margin-left: 15px;
  margin-right: 4px;
}
</style>
